<template>
    <div class="job_news">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div id="job_news_tabs">
<!--                <div class="sub-menu">-->
<!--                  <ul>-->
<!--                    <li :key="index" v-for="(category, index) in categorys">-->
<!--                      <a href="javascript:void(0);" class="item" :class="[(activeItem == category.id) ? 'visible':'']" @click="paymentActive(category.id)">-->
<!--                          <span>{{ category.name }}</span>-->
<!--                      </a>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
                <Tabs type="card">
                  <TabPane :label="'baiguullagin_ajliin_zar' | translate">
                    <baiguullaga></baiguullaga>
                  </TabPane>
                  <TabPane :label="'brigad_ajliin_zar' | translate">
                    <brigad></brigad>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
const brigad = () => import(/* webpackChunkName: "brigad_zar" */ './brigad_zar');
const baiguullaga = () => import(/* webpackChunkName: "baiguullaga_zar" */ './baiguullaga_zar');
import {mapGetters} from "vuex";

export default {
    name: "p_job_news",
    components: {
      "baiguullaga": baiguullaga,
      "brigad": brigad,
    },
    data() {
        return {
          // activeItem: 1,
          // categorys: [
          //   {
          //     id: 1,
          //     name: 'baiguullaga'
          //   },
          //   {
          //     value: 2,
          //     name: 'brigad'
          //   },
          // ],
        }
    },
    computed: {
        ...mapGetters([
            'languages',
            'language',
            // ...
        ]),
    },
    mounted() {},
    methods: {
      // paymentActive(p) {this.activeItem = p;},

      // handleReachBottom() {
      //   return new Promise(resolve => {
      //     setTimeout(() => {
      //       const last = this.list1[this.list1.length - 1];
      //       for (let i = 1; i < 11; i++) {
      //         this.list1.push(last + i);
      //       }
      //       resolve();
      //     }, 2000);
      //   });
      // },
    }


}
</script>

<style scoped>

</style>



